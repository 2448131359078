<template>
  <ul v-if="!codeFree" class="methods-list" data-cy="payment-methods">
    <Spinner v-if="isExtendPaymentMethodsLoading" class="w-6 h-6 mx-auto" />
    <template v-else>
      <li v-for="paymentMethod in internalPaymentMethods" :key="paymentMethod?.code">
        <RadioInput
          :id="`paymentMethod_${paymentMethod?.code}`"
          label=""
          name="paymentMethod"
          track-by="code"
          :value="paymentMethod"
          reversed
        >
          <div v-if="!paymentMethod?.code.includes('valu')" class="ml-2 flex items-center justify-between">
            <img
              v-if="paymentMethod"
              :src="resolveMethodIcon(paymentMethod) ? resolveMethodIcon(paymentMethod) : '/payment/credit-card.svg'"
              height="25"
              width="25"
              :alt="paymentMethod?.title"
              class="mr-2"
            />

            <p class="flex flex-col">
              <span class="font-bold">{{ paymentMethod?.title }}</span>
              <span v-if="paymentMethod?.description" class="text-gray-500">{{ paymentMethod.description }}</span>
            </p>
            <div v-if="paymentMethod?.code === 'robusta_mpgs_cc'" class="flex gap-2 lg:gap-5 items-center mr-2 lg:mr-7">
              <svg-icon-master_card class="w-7 h-4 object-contain lg:w-10 lg:h-10" />
              <svg-icon-visa class="w-9 h-3 object-contain lg:w-12 lg:h-12" />
            </div>
          </div>

          <div v-else class="flex items-center justify-between w-full">
            <p class="flex flex-col">
              <span class="font-bold">{{ $t('valu') }}</span>
              <span v-if="paymentMethod?.description" class="text-gray-500">{{ paymentMethod.description }}</span>
            </p>

            <img src="/valu.png" width="80" height="24" alt="" class="h-6 w-20 object-cover mr-3" />
          </div>

          <template #after>
            <PaymentStrategy
              v-if="
                selectedMappedMethod &&
                paymentMethod?.code === selectedMappedMethod.code &&
                selectedMappedMethod.component
              "
              :payment-component="selectedMappedMethod.component"
              class="mt-5"
            />
          </template>
        </RadioInput>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import type { AvailablePaymentMethod } from '@robustastudio/e-commerce/common';
import { useExtendPaymentMethods } from '~/composables/cart';

type InternalPaymentMethod = AvailablePaymentMethod & { description: string };

const { t: $t } = useI18n({
  useScope: 'local',
});

const { isLoggedIn } = useAuth();
const {
  executeExtendCustomerPaymentMethods,
  executeExtendGuestPaymentMethods,
  isExtendPaymentMethodsLoading,
  extendedPaymentMethods,
} = useExtendPaymentMethods();

function resolveMethodIcon(paymentMethod: Omit<AvailablePaymentMethod, 'is_deferred'>): string {
  const mapIcons: Record<string, string> = {
    cashondelivery: '/payment/cash-on-delivery.svg',
    robusta_accept_cc: '/payment/credit-card.svg',
    cardondelivery: '/payment/credit-on-delivery.svg',
  };

  return mapIcons[paymentMethod.code];
}

const props = defineProps({
  paymentMethods: {
    type: Array as PropType<Array<Omit<AvailablePaymentMethod, 'is_deferred'> | null | undefined>>,
    default: () => [],
  },
  codeFree: {
    type: Boolean,
    default: () => false,
  },
});

const { value: selectedPaymentMethod } = useField<AvailablePaymentMethod>('paymentMethod');
const internalPaymentMethods = ref<Array<Omit<InternalPaymentMethod, 'is_deferred'>>>([]); // This will hold for payment methods (core & extended) attributes

const selectedMappedMethod = computed(
  () =>
    selectedPaymentMethod.value &&
    props.paymentMethods
      .filter((method: any): method is AvailablePaymentMethod => method !== null)
      .map(method => mapPaymentOption(method))
      .find(method => method.code === selectedPaymentMethod.value?.code),
);

/**
 * watch for any payment methods update
 * Then fetch the extended payment methods again to match the new payment methods
 * The internalPaymentMethods will hold the all payment methods information (core & extended)
 */
watch(
  () => props.paymentMethods,
  async newValue => {
    if (!newValue.length) return;

    if (isLoggedIn.value) {
      await executeExtendCustomerPaymentMethods();
    } else {
      await executeExtendGuestPaymentMethods();
    }

    internalPaymentMethods.value = newValue.map((method, index) => ({
      ...method,
      code: method?.code || '',
      title: method?.title || '',
      description: extendedPaymentMethods.value[index]?.description || '',
    }));
  },
  { immediate: true },
);
</script>
<style lang="postcss" scoped>
.methods-list li:not(:last-child) {
  @apply flex flex-col;
  &::after {
    @apply bg-primary-1-10 my-5;
    content: '';
    width: 100%;
    height: 1px;
  }
}

:deep(label[for='paymentMethod_robusta_accept_valu']) {
  .Label {
    display: inline-block;
    width: 100%;
  }
}
</style>

<i18n>
  {
    "en": {
      "valu": "Pay with Valu"
    },
    "ar": {
      "valu": "ادفع مع Valu"
    }
  }
</i18n>
