<template>
  <div class="relative flex flex-col">
    <h3 class="flex mb-5">
      <svg-icon-coupon width="25" height="25" class="mr-2 text-primary-1-100" />
      {{ $t('haveAPromoCode') }}
      <svg-icon-redeemed v-if="appliedCoupon.length" width="22" height="22" class="ml-2" />
    </h3>
    <template v-if="!appliedCoupon.length">
      <button
        :aria-label="$t('haveAPromoCode')"
        :aria-pressed="Boolean(toggleCoupons)"
        type="button"
        @click="toggleCoupons = !toggleCoupons"
      >
        <span
          class="checkbox p-1 ml-auto flex justify-center items-center absolute top-0 right-0 rounded-md"
          :class="{
            active: toggleCoupons || appliedCoupon.length,
          }"
        >
          <svg-icon-check :class="{ invisible: !toggleCoupons, 'text-white': toggleCoupons }" class="stroke-current" />
        </span>
      </button>
    </template>

    <form @submit="onSubmit">
      <Coupons
        v-if="toggleCoupons || appliedCoupon.length"
        :applied-coupons="appliedCoupon"
        :loading="loading"
        class="block"
        @remove="() => removeCouponOnCart()"
      >
        <template #cancel>
          <button
            type="button"
            class="text-system-red font-body underline ml-5"
            @click="toggleCoupons = !toggleCoupons"
          >
            {{ $t('cancel') }}
          </button>
        </template>
      </Coupons>
    </form>
  </div>
</template>
<script setup lang="ts">
import { object, string } from 'yup';

defineComponent({
  name: 'CheckoutCoupons',
});

const { appliedCoupon: cartCoupons } = useCartAttributes();
const { isFetching: isApplyingPromoCode, applyCoupon } = useExtendApplyPromocode();
const { isFetching: isRemovingPromoCode, removeCode } = useRemovePromocode();

const { error, success } = useAlerts();
const { t } = useI18n();
const { t: $t } = useI18n({
  useScope: 'local',
});

const { handleSubmit, setErrors, resetForm } = useForm({
  validationSchema: toTypedSchema(
    object({
      code: string().required(),
    }),
  ),
});

const toggleCoupons = ref(false);

const emit = defineEmits<{
  (event: 'isOpen', isOpen: boolean): void;
}>();
const onSubmit = handleSubmit(async ({ code }) => {
  try {
    await applyCoupon(code);
    success(t('couponAdded').toString());
    toggleCoupons.value = false;
    resetForm();
  } catch (e) {
    error(t('cartError').toString(), t((e as Error).message).toString());

    setErrors({ code: t((e as Error).message).toString() });
  }
});

async function removeCouponOnCart() {
  try {
    await removeCode();
    success(t('couponRemoved').toString());
  } catch (e) {
    error(t('cartError').toString(), t((e as Error).message).toString());
  }
}

watch(
  toggleCoupons,
  newValue => {
    emit('isOpen', newValue);
  },
  {
    immediate: true,
  },
);

const appliedCoupon = computed(() => (cartCoupons.value ? [cartCoupons.value] : []));

const loading = computed(() => isApplyingPromoCode.value || isRemovingPromoCode.value);
</script>
<i18n>
{
  "en": {
    "haveAPromoCode": "Do you have a promocode?",
    "cancel": "Cancel",
    "notActive": "This Code is not active",
    "couponAdded": "coupon added successfully",
    "couponRemoved": "voucher removed successfully"
  },
  "ar": {
    "haveAPromoCode": "لديك برومو كود ؟",
    "cancel": "الغاء",
    "notActive": "هذا الكود غير نشط",
    "couponAdded": "تم تطبيق الكوبون بنجاح",
    "couponRemoved": "تم ازالة الكوبون بنجاح"
  }
}
</i18n>

<style lang="postcss" scoped>
.checkbox {
  @apply h-6 w-6  border border-primary-1-100 mr-2;

  &.active {
    @apply bg-primary-1-100 border-white;
  }
}
</style>
